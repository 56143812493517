import React, { Fragment } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import Holly from '../../assets/Holly.png'

export const AboutPage = () => {
  return (
    <Fragment>
      <Row>
        <Col md={2}>
          <Image src={Holly} roundedCircle fluid className='my-5 mx-3'></Image>
        </Col>
        <Col className='px-5' md={10}>
          <h2 className='display-4 my-4 font-weight-bold'>Our Mission</h2>
          <h3>
            Guided by a fundamental belief in the immense potential within every student, Growth
            Academy Learning Center is dedicated to helping each student flourish academically,
            socially, and emotionally. Through personalized tutoring services, both one-on-one and
            small group sessions, we create a nurturing environment where students can develop
            confidence and achieve success. Our approach is rooted in mentorship and individualized
            education plans, tailored to meet each student's unique needs, ensuring they have the
            tools and support to reach their full potential.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className='px-5'>
          <h2 className='display-4 my-4 font-weight-bold'>Core Values</h2>
          <h2>Leadership</h2>
          <h3>
            At Growth Academy Learning Center we emphasize leadership not just in academics, but in
            character. We encourage students and team members to lead by exapmle, inspire others,
            and take initiative in their learning and growth. We seek individuals who empower
            students to become confident, proactive learners and future leaders.
          </h3>
          <h2>Excellence</h2>
          <h3>
            We strive for excellence in every aspect of our work -- from tutoring services to
            communication and support. Our commitment to excellence ensures that students receive
            the highest quality education and support. We look for team members who are dedicated to
            upholding high standards and who inspire students to pursue their best.
          </h3>
          <h2>Accountability</h2>
          <h3>
            Being accountable is essential to building trust and achieving success. At Growth
            Academy Learning Center, we hold ourselves and each other accountable for meeting goals
            and maintaining a high level of integrity. We seek team members who are reliable,
            transparent, and committed to taking responsibility for their work and the progress of
            their students.
          </h3>
          <h2>Respect</h2>
          <h3>
            Respect is the foundation of a supportive and effective learning environment. At Growth
            Academy Learning Center, we value the diverse backgrounds, experiences, and perspectives
            of our students and staff. We seek individuals who treat others with kindness, listen
            actively, and create a safe and welcoming space for everyone.
          </h3>
          <h2>Nurture</h2>
          <h3>
            We believe in nurturing every student's potential. At Growth Academy Learning Center, we
            provide an environment where students can grow not only academically but emotionally and
            socially. We look for team members who are compassionate, patient, and dedicated to
            mentoring students to help them flourish and achieve their goals.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className='px-5'>
          <h2 className='display-4 my-4 font-weight-bold'>History</h2>
          <h3 className='my-4'>
            Founded by Holly Wilson, Growth Academy Learning Center was born out of a deep passion
            for education and a frustration with the lack of quality support for students who faced
            unique learning challenges. With over 20 years of teaching experience across diverse
            educational environments in the United States and China -- ranging from private and
            public schools to international institutions and university-level teaching -- Holly
            witnessed firsthand how many students struggled either because they had learning gaps
            that were not being addressed or because they weren't being appropriately challenged,
            which led to boredom and disengagement.
          </h3>
          <h3 className='my-4'>
            Determined to bridge this gap, Holly established Growth Academy Learning Center with a
            mission to provide personlized, high-quality tutoring that meets each student exactly
            where they are. Whether a student needs support filling in the gaps in their knowledge
            or extra challenges to stretch their abilities, the focus is always on academic success
            and helping students thrive emotionally and socially as they progress.
          </h3>
          <h3 className='my-4'>
            Growth Academy Learning Center is dedicated to nurturing every student's potential,
            empowering them with the tools they need to succeed in school and beyond, and guiding
            them toward a brighter, more successful future.
          </h3>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AboutPage
