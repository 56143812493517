import React, { Fragment, useState } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import Contact from '../../assets/ContactUs.jpg'
import './ContactForm.css'

export default function ContactForm() {
  const initialState = { name: '', email: '', message: '' }
  const [eachEntry, setEachEntry] = useState(initialState)
  const { name, email, message } = eachEntry

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  return (
    <Fragment>
      <Container fluid className='m-0 p-0 contactImage'></Container>
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <h1 className='my-3'>Contact Us</h1>
          <p>
            If you're interested in learning more about our services or have questions, please
            contact us through the form below.
          </p>
          <Form>
            <Form.Group controlId='name' className='my-3'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={name}
                type='text'
                placeholder='First Last'
              />
            </Form.Group>
            <Form.Group controlId='email' className='my-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={email}
                type='text'
                placeholder='youremail@domain.com'
              />
            </Form.Group>
            <Form.Group controlId='message' className='my-3'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={message}
                as='textarea'
                rows={6}
                placeholder='Type your message here.'
              />
            </Form.Group>
            <Button variant='primary' type='submit' className='my-3'>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}
