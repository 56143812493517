import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { Layout } from './Layout'
import { Container } from 'react-bootstrap'
import ContactForm from './Contact/ContactForm'
import AboutPage from './About/AboutPage'

const Dashboard = lazy(() => import('./Home/Dashboard'))

function App() {
  return (
    <Container fluid>
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path='/'>
              <Route path='' element={<Dashboard />} />
              <Route path='Contact' element={<ContactForm />} />
              <Route path='About' element={<AboutPage />} />
            </Route>
          </Routes>
        </Suspense>
      </Layout>
    </Container>
  )
}

export default App
